import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
//import createPersistedState from 'vuex-persistedstate'
// modulos
import acopio from './modules/acopio'
import articulo from './modules/articulo'
import bodega from './modules/bodega'
import centro from './modules/centro'
import centro_costo from './modules/centro_costo'
import cuenta from './modules/cuenta'
import empresa from './modules/empresa'
import especie from './modules/especie'
import moneda from './modules/moneda'
import muelle from './modules/muelle'
import planta from './modules/planta'
import sentido from './modules/sentido'
import tipo_costo from './modules/tipo_costo'
import tipo_destino from './modules/tipo_destino'
import tipo_origen from './modules/tipo_origen'
import tipo_parte from './modules/tipo_parte'
import wellboat from './modules/wellboat'

Vue.use(Vuex)

// let usar = 'avanze';
let usar = process.env.VUE_APP_USAR_STORE_INDEX_JS;
let domain = window.location.hostname;
//console.log(domain);
let base_url = 'http://localhost/lapsa/wellboats/backend/public/api/';
let storage_url = 'http://localhost/lapsa/wellboats/backend/storage/app/public/';
if(usar == 'nuc'){
  base_url = `http://${domain}/backend/public/api/`;
  storage_url = `http://${domain}/backend/storage/app/public/`;
}
if(usar == 'queilen'){
  base_url = 'http://192.168.0.6/sistema/wellboat_backend/public/api/';
  storage_url = 'http://192.168.0.6/sistema/wellboat_backend/storage/app/public/';
}
if(usar == 'avanze'){
  base_url = 'https://www.avanze.cl/develop/lapsa/wellboat/backend/public/api/';
  storage_url = 'https://www.avanze.cl/develop/lapsa/wellboat/backend/storage/app/public/';
}

const store = new Vuex.Store({
  state: {
    base_url: base_url,
    storage_url: storage_url,
    user: {wellboat: {id: 0, nombre: 'Ninguno'}},
    rol: null,
    headers: null,
    headers_file:null,
    headers_json:null,
    token: null,
    drawer: false,
    loading:{
      titulo: 'Cargando...',
      state: false
    },
    pageTitle: 'SISTEMA WELLBOATS',
    parte: {}
  },
  mutations: {
    initializeStore(state) {
			if(localStorage.getItem('wellboat_store')) {
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('wellboat_store')))
				);
			}
		},
    setSession(state, data){
      state.user     = data.user;
      state.token    = data.access_token;
      state.rol      = data.user.rol;
      state.wellboat = data.user.wellboat;
      state.headers  = {
        headers:{
          'Content-Type' : 'application/json;charset=utf-8',
          'Authorization': `Bearer ${state.token}`
        }
      };
      state.headers_json = {
        "headers":{
          "Content-Type" : "application/json;charset=utf-8",
          "Authorization" : `Bearer ${state.token}`
        }
      },
      state.headers_file = {
        "headers":{
          "Content-Type" : "multipart/form-data",
          "Authorization" : `Bearer ${state.token}`
        }
      }
      router.push('/dashboard');
    },
    removeSession(state){
      state.user = {};
      state.user.wellboat = {id: 0, nombre: 'Ninguno'};
      state.rol = {};
      state.token = null;
      state.headers = null;
      router.push('/');
    },
    mostrarLoading(state, payload){
      state.loading.titulo = payload;
      state.loading.state = true;
    },
    ocultarLoading(state){
      state.loading.state = false;
    },
    actualizarBaseUrl(state, url){
      state.base_url = url;
    },
    actualizarUrlStorage(state, url){
      state.storage_url = url;
    },
    actualizarWellboat(state, w){
      state.user.wellboat = w;
    },
    setParte(state, parte){
      state.parte = parte;
      //console.log('seteando parte',parte);
    }
  },
  actions: {
    iniciarSession({commit}, datos){
      commit('setSession', datos);
    },
    cerrarSession({commit}){
      commit('removeSession');
    }
  },
  modules: {
    acopio, articulo, bodega, centro, centro_costo, cuenta, empresa, especie, moneda, muelle, planta, sentido, tipo_costo, tipo_destino, tipo_origen, tipo_parte, wellboat
  },
  // plugins: [createPersistedState({
  //   paths: ['token','user','headers','wellboat','parte']
  // })]
  
})

store.subscribe((mutation, state) => {
  localStorage.setItem('wellboat_store', JSON.stringify(state));
})

export default store